import Router from 'vue-router'
import KigeWrapper from './layouts/KigeWrapper.vue'
import Profile from "./pages/Profile.vue"
import Certificates from "./pages/Certificates.vue"
import Adjustments from "./pages/Adjustments.vue"
import Groups from "./pages/Groups.vue"
import TargetGroupCreate from './pages/TargetGroups/TargetGroupCreate.vue'
import PersonDetail from './pages/PersonDetail.vue'
import ImportData from './pages/ImportData.vue'
import LetterIndex from "./pages/Letter/LetterIndex.vue"
import LetterCreate from "./pages/Letter/LetterCreate.vue"
import PersonLookup from "./pages/PersonLookup"
import Login from './pages/Login'
import store from './store'
import PersonCreate from "@/modules/kirchgeld/pages/Person/PersonCreate"
import PersonEdit from "@/modules/kirchgeld/pages/Person/PersonEdit"
import KigeHome from "@/modules/kirchgeld/pages/KigeHome"
import LegacyContextK from '@/modules/kirchgeld/pages/LegacyContextK.vue'
import OrganizationMerge from '@/modules/kirchgeld/pages/OrganizationMerge.vue'

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '',
      redirect: {
        name: 'kige-index'
      }
    },
    {
      path: '/',
      component: KigeWrapper,
      children: [
        {
          path: '',
          name: 'kige-index',
          component: KigeHome,
        },
        {
          path: '/login',
          name: 'kige-login',
          component: Login,
        },
        {
          path: '/profile',
          name: 'kige-profile',
          component: Profile,
        },
        {
          path: '/certificates',
          name: 'kige-certificates',
          component: Certificates,
        },
        {
          path: '/letters',
          name: 'kige-letter-index',
          component: LetterIndex,
        },
        {
          path: '/letters/create',
          name: 'kige-letter-create',
          component: LetterCreate,
          props: true
        },
        {
          path: '/groups',
          name: 'kige-groups',
          component: Groups,
        },
        {
          path: '/adjustments',
          name: 'kige-adjustments',
          component: Adjustments,
        },
        {
          path: '/organization/merge',
          name: 'kige-organization-merge',
          component: OrganizationMerge,
        },
      ]
    },
    {
      path: '/target-groups',
      component: KigeWrapper,
      children:
      [
        {
          path: 'create',
          name: 'kige-target-groups-create',
          component: TargetGroupCreate
        }
      ]
    },
    {
      path: '/persons',
      component: KigeWrapper,
      children: [
        {
          path: '',
          name: 'kige-person-index',
          component: TargetGroupCreate
        },
        {
          path: 'create',
          name: 'kige-person-create',
          component: PersonCreate,
        },
        {
          path: 'detail/:personUrl/:dataId?',
          name: 'kige-person-detail',
          component: PersonDetail,
          props: true
        },
        {
          path: 'lookup',
          name: 'kige-person-lookup',
          component: PersonLookup
        },
        {
          path: 'edit/:url',
          name: 'kige-person-edit',
          component: PersonEdit,
          props: true
        }
      ]
    },
    {
      path: '/imports',
      component: KigeWrapper,
      children: [
        {
          path: '',
          name: 'kige-imports',
          component: ImportData
        }
      ]
    },
    {
      path: '/legacy/contextk',
      component: KigeWrapper,
      children: [
        {
          path: '',
          name: 'kige-legacy-context-k',
          component: LegacyContextK
        }
      ]
    },

  ]
})

router.beforeEach((to, from, next) => {
  if (!["kige-index", "kige-login"].includes(to.name) && !store.getters['kirchgeld/isLoggedIn']) {
    next({ name: "kige-index" })
  } else next()
})

export default router
