<template>
  <v-card>
    <v-card-title>Neue Organisation erstellen</v-card-title>
    <v-card-text>
      <v-form v-model="createForm">
        <v-container>
          <v-row>
            <v-col cols="12"><v-text-field v-model="name" :rules="[!!name || 'Verpflichtend']" hint="Neuer Organisationsname" placeholder="Name" /></v-col>
          </v-row>
          <v-row>
            <v-col cols="3"><v-text-field type="date" v-model="validFrom" :rules="[!!validFrom || 'Verpflichtend']" class="ml-1" style="max-width: 9em" hint="Gültig ab"/></v-col>
            <v-col cols="3"><v-text-field v-model="kro" :rules="[!!kro || 'Verpflichtend']" hint="Neue KRO" placeholder="KRO" /></v-col>
            <v-col cols="6"><v-text-field v-model="externalId" hint="Externe ID (optional)" placeholder="Externe ID" /></v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="organizations"
                :items="organizationsHumanName"
                item-text="name"
                item-value="url"
                placeholder="Alte Organisationen"
                multiple
                clearable
                chips
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2" offset="10"><v-btn @click="createOrganization" :disabled="!createForm">Anlegen</v-btn></v-col>
          </v-row>
        </v-container>
      </v-form>
      <pre>{{results}}</pre>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  GET_ORGANIZATIONS,
  GET_TENANTS
} from '@/modules/kirchgeld/store/kirchgeld/action-types'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'OrganizationMerge',
  data: function () {
    return {
      createForm: false,
      name: '',
      validFrom: '',
      kro: '',
      organizations: [],
      results: "",
      externalId: "",
    }
  },
  async mounted () {
    await this.$store.dispatch('kirchgeld/' + GET_TENANTS)
    await this.$store.dispatch('kirchgeld/' + GET_ORGANIZATIONS)
  },
  computed: {
    ...mapGetters('kirchgeld', ['organizationsHumanName']),
  },
  methods: {
    async createOrganization () {
      const payload = {
        name: this.name,
        valid_from: moment(this.validFrom).toISOString(),
        kro_nr: this.kro,
        external_id: this.externalId || null,
        replaces: this.organizations,
      }
      this.results = (await this.$store.getters['kirchgeld/api'].post(`${this.$store.state.kirchgeld.tenant.url}organization/`, payload))?.data ?? ""
    }
  }

}
</script>

<style scoped lang="stylus">

</style>
