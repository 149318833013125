<template>
  <span>
    <v-btn
      small
      elevation="2"
      fab
      color="primary"
      class="settingsButton ml-2"
      @click.stop="showDialog = true"
    >
      <v-icon>{{ mdiHelp }}</v-icon>
    </v-btn>
    <base-dialog
      :is-open="showDialog"
      @close="showDialog = false"
    >
      <template #dialog-title>Details zu den einzelnen Benachrichtigungen</template>
      <template #dialog-content>
        <v-card-text>
          <ul>
            <li
              v-for="(label, contactType) in enumsByName.ContactType"
              :key="contactType"
            ><b>{{ label }}</b> - {{ $tc('contactTypes.helpText.' + contactType) }}</li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="showDialog = false"
            color="primary"
          >Schließen
          </v-btn>
        </v-card-actions>
      </template>
    </base-dialog>
  </span>
</template>
<script>
import { mdiClock, mdiHelp } from "@mdi/js"
import BaseDialog from "@/components/UI/BaseDialog.vue"
import { mapState } from "vuex"
export default {
  components: { BaseDialog },
  data () {
    return {
      mdiHelp,
      mdiClock,
      showDialog: false,
    }
  },
  computed: {
    ...mapState('staticConfiguration', ['enumsByName']),
  }
}
</script>
